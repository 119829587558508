import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Header from "components/Header/Header.jsx";
import CourseHeaderLinks from "./CourseHeaderLinks.jsx";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import IdleTimer from 'react-idle-timer'
import { translate } from "react-switch-lang";
import { connect } from "react-redux";
import { logoutUser } from "actions/loginActions";
import { idleTimerAlert } from "actions/generalActions";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  ...sweetAlertStyle,
  ...loginPageStyle
};
class CourseHeader extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      timer: true 
    };
    this.idleTimer = null;
    this.handleOnAction = this.handleOnAction.bind(this);
    this.handleOnActive = this.handleOnActive.bind(this);
    this.handleOnIdle = this.handleOnIdle.bind(this);
  }

  handleOnAction(event) {
    if (!this.state.timer) {
      this.props.dispatchLogoutUser()
    }
  }

  handleOnActive(event) {
  }

  handleOnIdle(event) {
    this.setState({ timer: false });
    this.props.dispatchIdleTimeAlert();
    setTimeout(this.handleOnAction, 1000 * 60 * 5);
  }

  render() {
    const { classes, rol,idle_timer_modal, ...rest } = this.props;
    let { t } = this.props;
    return (
      <>
      {/* <IdleTimer
        ref={ref => { this.idleTimer = ref }}
        timeout={1000 * 60 * 15}
        onActive={this.handleOnActive}
        onIdle={this.handleOnIdle}
        onAction={this.handleOnAction}
        debounce={250}
      /> */}
      {
        idle_timer_modal ?
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            onConfirm={() => this.handleOnAction()}
            title={t("label_session_expired")}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
          >
          </SweetAlert>
          : ""
      }
      <Header
        color="white"
        brand="assets/img/interweavelogo22.png"
        rightLinks={<CourseHeaderLinks rol={rol} />}
        {...rest}
      />
    </>
    );
  }
}

const mapStateToProps = state => ({
  idle_timer_modal: state.generalReducer.idle_timer_modal
});

const mapDispatchToPropsActions = dispatch => ({
  dispatchLogoutUser: redirect => dispatch(logoutUser(redirect)),
  dispatchIdleTimeAlert: () => dispatch(idleTimerAlert()),
});

const CourseHeaderComponent = translate(withStyles(styles)(CourseHeader));
export default connect(mapStateToProps, mapDispatchToPropsActions)(CourseHeaderComponent);

