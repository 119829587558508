import { GET_REPORTS, GET_REPORT_COUNTRY, GET_AMBASSADOR_COUNTRY, GET_REPORT_AMBASSADOR, GET_REPORT_GLOBAL_MAP } from 'constants/actionTypes.jsx';
import { AMBASSADOR_STATISTICS, GET_GLOBAL_NUMBERS, AMBASSADOR_GROUP_REPORTS } from 'constants/actionTypes';
import { STATISTICS_GLOBAL_AMBASSADOR, LAST_YEAR_GRADUATES_REPORT, LAST_YEAR_SA_GRADUATES_REPORT } from 'constants/actionTypes';
import { LAST_MONTH_GRADUATES_REPORT, LAST_MONTH_SA_GRADUATES_REPORT } from 'constants/actionTypes';
import { convertDate } from 'assets/functions/general';
import { LAST_SIX_MONTHS_GRADUATES_REPORT, LAST_SIX_MONTHS_SA_GRADUATES_REPORT } from 'constants/actionTypes';
import { DATE_RANGE_GRADUATES_REPORT, DATE_RANGE_SA_GRADUATES_REPORT } from 'constants/actionTypes';
import { GET_MBS_GRADUATES_BY_LANGUAGE } from 'constants/actionTypes';
import { SUCCESSFUL_LOAD } from 'constants/actionTypes';
import { DELETE_SUCCESSFUL } from 'constants/actionTypes';
import history from '../history';
import { SESSION_EXPIRED } from 'constants/actionTypes';
import { CHANGE_ACTIVE_TAB_REPORTS } from 'constants/actionTypes';
import { GET_REPORT_MODALITY_MAP } from 'constants/actionTypes';
import { LAST_YEAR_WELLNESS_GRADUATES_REPORT } from 'constants/actionTypes';
import { LAST_MONTH_WELLNESS_GRADUATES_REPORT } from 'constants/actionTypes';
import { LAST_SIX_MONTHS_WELLNESS_GRADUATES_REPORT } from 'constants/actionTypes';
import { DATE_RANGE_WELLNESS_GRADUATES_REPORT } from 'constants/actionTypes';
import { LAST_YEAR_WELLNESS_PARTICIPANT_GRADUATES_REPORT } from 'constants/actionTypes';
import { LAST_MONTH_WELLNESS_PARTICIPANT_GRADUATES_REPORT } from 'constants/actionTypes';
import { DATE_RANGE_WELLNESS_PARTICIPANT_GRADUATES_REPORT } from 'constants/actionTypes';
import { LAST_SIX_MONTHS_WELLNESS_PARTICIPANT_GRADUATES_REPORT } from 'constants/actionTypes';

export const getReports = (key) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var id = "";
        var role = "";
        if (reduxState.loginReducer.active_user.roles[0] == "ROLE_ADMIN" && key != undefined) {
            id = key;
            role = "ROLE_EMBASSADOR"
        }
        else if (reduxState.loginReducer.active_user.roles.includes("ROLE_LANGUAGE_ADMIN") || reduxState.loginReducer.active_user.roles.includes("ROLE_ADMIN")) {
            id = reduxState.loginReducer.active_user.id
            role = "ROLE_ADMIN"
        }
        else if (reduxState.loginReducer.active_user.roles[0] == "ROLE_EMBASSADOR") {
            id = reduxState.loginReducer.active_user.id
            role = "ROLE_EMBASSADOR"
        }

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("role", role);
        urlencoded.append("id", id);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        return fetch(reduxState.loginReducer.base_url + "/report/", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: GET_REPORTS, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const getReportCountry = (key) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/report/evaluations/" + key + "?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: GET_REPORT_COUNTRY, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const getAmbassadorCountry = (key) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/ambassador/country/" + key + "?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: GET_AMBASSADOR_COUNTRY, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const getReportAmbassador = (key) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        if (reduxState.loginReducer.active_user.roles[0] == "ROLE_EMBASSADOR") {
            key = reduxState.loginReducer.active_user.id
        }
        return fetch(reduxState.loginReducer.base_url + "/report/ambassador/" + key + "?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: GET_REPORT_AMBASSADOR, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const getReportGlobalMap = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/report/globalmap", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: GET_REPORT_GLOBAL_MAP, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const getReportModalityMap = () => {
    return (dispatch, getState) => {
        dispatch({ type: DELETE_SUCCESSFUL });
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/report/modalitymap", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: GET_REPORT_MODALITY_MAP, payload: json.data });
                    dispatch({ type: SUCCESSFUL_LOAD });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const globalNumbers = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var urlencoded = new URLSearchParams();
        urlencoded.append("id", reduxState.loginReducer.active_user.id);
        urlencoded.append("role", reduxState.loginReducer.active_user.roles[0]);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        }

        return fetch(reduxState.loginReducer.base_url + "/report/globalnumbers", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: GET_GLOBAL_NUMBERS, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const globalNumbersEmbassador = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var urlencoded = new URLSearchParams();
        urlencoded.append("id", reduxState.loginReducer.active_user.id);
        urlencoded.append("role", "ROLE_ADMIN");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        }

        return fetch(reduxState.loginReducer.base_url + "/report/globalnumbers", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: GET_GLOBAL_NUMBERS, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const reportsGroupList = (key) => {
    return (dispatch, getState) => {
        const reduxState = getState();

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var urlencoded = new URLSearchParams();
        urlencoded.append("id_ambassador", key);
        urlencoded.append("role", "ROLE_EMBASSADOR");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        return fetch(reduxState.loginReducer.base_url + "/group/?callback=foo", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: AMBASSADOR_GROUP_REPORTS, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

//-----------Nuevas Acciones Para estadisticas por embajador-----------------

export const getAmbassadorStatistics = (key) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/report/listambassadorstatistics", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: AMBASSADOR_STATISTICS, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const statisticsGlobalAmbassador = (key) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var urlencoded = new URLSearchParams();
        urlencoded.append("id", key);
        urlencoded.append("role", "ROLE_EMBASSADOR");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        }

        return fetch(reduxState.loginReducer.base_url + "/report/globalnumbers", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: STATISTICS_GLOBAL_AMBASSADOR, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};
//-----------------------------------------------------------------------------

export const lastYearGraduates = (key) => {

    return (dispatch, getState) => {
        dispatch({ type: DELETE_SUCCESSFUL });
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("country", reduxState.form.lyCountryForm === undefined ? "ALL" : reduxState.form.lyCountryForm.values.country);
        urlencoded.append("language", reduxState.form.languageForm === undefined ? "ALL" : reduxState.form.languageForm.values.language);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        }
        return fetch(reduxState.loginReducer.base_url + "/report/graduates/lastyear", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: LAST_YEAR_GRADUATES_REPORT, payload: json.data });
                    dispatch({ type: SUCCESSFUL_LOAD });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const lastMonthGraduates = (key) => {

    return (dispatch, getState) => {
        dispatch({ type: DELETE_SUCCESSFUL });
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("country", reduxState.form.lmCountryForm === undefined ? "ALL" : reduxState.form.lmCountryForm.values.country);
        urlencoded.append("language", reduxState.form.lmLanguageForm === undefined ? "ALL" : reduxState.form.lmLanguageForm.values.language);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        }
        return fetch(reduxState.loginReducer.base_url + "/report/graduates/lastmonth", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: LAST_MONTH_GRADUATES_REPORT, payload: json.data });
                    dispatch({ type: SUCCESSFUL_LOAD });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}


export const lastSixMonthsGraduates = (key) => {

    return (dispatch, getState) => {
        dispatch({ type: DELETE_SUCCESSFUL });
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("country", reduxState.form.lsmCountryForm === undefined ? "ALL" : reduxState.form.lsmCountryForm.values.country);
        urlencoded.append("language", reduxState.form.lsmLanguageForm === undefined ? "ALL" : reduxState.form.lsmLanguageForm.values.language);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        }
        return fetch(reduxState.loginReducer.base_url + "/report/graduates/lastsixmonths", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: LAST_SIX_MONTHS_GRADUATES_REPORT, payload: json.data });
                    dispatch({ type: SUCCESSFUL_LOAD });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const dateRangeGraduates = (key) => {

    return (dispatch, getState) => {
        dispatch({ type: DELETE_SUCCESSFUL });
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("start_date", convertDate(reduxState.form.dateRangeForm.values.start_date));
        urlencoded.append("final_date", convertDate(reduxState.form.dateRangeForm.values.final_date));
        urlencoded.append("country", reduxState.form.dateRangeForm.values.country);
        urlencoded.append("language", reduxState.form.dateRangeForm.values.language);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        }

        return fetch(reduxState.loginReducer.base_url + "/report/graduates/daterange", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: DATE_RANGE_GRADUATES_REPORT, payload: json.data });
                    dispatch({ type: SUCCESSFUL_LOAD });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const lastYearSaGraduates = (key) => {

    return (dispatch, getState) => {
        dispatch({ type: DELETE_SUCCESSFUL });
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("country", reduxState.form.lySaCountryForm === undefined ? "ALL" : reduxState.form.lySaCountryForm.values.country);
        urlencoded.append("language", reduxState.form.saLanguageForm === undefined ? "ALL" : reduxState.form.saLanguageForm.values.language);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        }
        return fetch(reduxState.loginReducer.base_url + "/report/graduates/sa/lastyear", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: LAST_YEAR_SA_GRADUATES_REPORT, payload: json.data });
                    dispatch({ type: SUCCESSFUL_LOAD });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const lastMonthSaGraduates = (key) => {
    return (dispatch, getState) => {
        dispatch({ type: DELETE_SUCCESSFUL });
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("country", reduxState.form.lmSaCountryForm === undefined ? "ALL" : reduxState.form.lmSaCountryForm.values.country);
        urlencoded.append("language", reduxState.form.lmSaLanguageForm === undefined ? "ALL" : reduxState.form.lmSaLanguageForm.values.language);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        }
        return fetch(reduxState.loginReducer.base_url + "/report/graduates/sa/lastmonth", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: LAST_MONTH_SA_GRADUATES_REPORT, payload: json.data });
                    dispatch({ type: SUCCESSFUL_LOAD });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}


export const lastSixMonthsSaGraduates = (key) => {
    return (dispatch, getState) => {
        dispatch({ type: DELETE_SUCCESSFUL });
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("country", reduxState.form.lsmSaCountryForm === undefined ? "ALL" : reduxState.form.lsmSaCountryForm.values.country);
        urlencoded.append("language", reduxState.form.lsmSaLanguageForm === undefined ? "ALL" : reduxState.form.lsmSaLanguageForm.values.language);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        }
        return fetch(reduxState.loginReducer.base_url + "/report/graduates/sa/lastsixmonths", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: LAST_SIX_MONTHS_SA_GRADUATES_REPORT, payload: json.data });
                    dispatch({ type: SUCCESSFUL_LOAD });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const dateRangeSaGraduates = (key) => {
    return (dispatch, getState) => {
        dispatch({ type: DELETE_SUCCESSFUL });
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("start_date", convertDate(reduxState.form.dateRangeSaForm.values.start_date));
        urlencoded.append("final_date", convertDate(reduxState.form.dateRangeSaForm.values.final_date));
        urlencoded.append("country", reduxState.form.dateRangeSaForm.values.country);
        urlencoded.append("language", reduxState.form.dateRangeSaForm.values.language);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        }

        return fetch(reduxState.loginReducer.base_url + "/report/graduates/sa/daterange", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: DATE_RANGE_SA_GRADUATES_REPORT, payload: json.data });
                    dispatch({ type: SUCCESSFUL_LOAD });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const lastYearAmbassadorGraduates = (key) => {

    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("ambassador_id", key);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        }
        return fetch(reduxState.loginReducer.base_url + "/report/graduates/ambassador/lastyear", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: LAST_YEAR_GRADUATES_REPORT, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const lastMonthAmbassadorGraduates = (key) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("ambassador_id", key);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        }
        return fetch(reduxState.loginReducer.base_url + "/report/graduates/ambassador/lastmonth", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: LAST_MONTH_GRADUATES_REPORT, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}


export const lastSixMonthsAmbassadorGraduates = (key) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("ambassador_id", key);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        }
        return fetch(reduxState.loginReducer.base_url + "/report/graduates/ambassador/lastsixmonths", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: LAST_SIX_MONTHS_GRADUATES_REPORT, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const dateRangeAmbassadorGraduates = (key) => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("start_date", convertDate(reduxState.form.dateRangeForm.values.start_date));
        urlencoded.append("final_date", convertDate(reduxState.form.dateRangeForm.values.final_date));
        urlencoded.append("ambassador_id", key);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        }

        return fetch(reduxState.loginReducer.base_url + "/report/graduates/ambassador/daterange", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: DATE_RANGE_GRADUATES_REPORT, payload: json.data });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};

export const getMbsGraduatesByLanguage = () => {
    return (dispatch, getState) => {
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: myHeaders,
        };
        return fetch(reduxState.loginReducer.base_url + "/report/graduates/mbs/language", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: GET_MBS_GRADUATES_BY_LANGUAGE, payload: json.data })
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const changeActiveTabReports = (key) => ({ type: CHANGE_ACTIVE_TAB_REPORTS, payload: key })

export const lastYearWellnessGraduates = () => {

    return (dispatch, getState) => {
        dispatch({ type: DELETE_SUCCESSFUL });
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("country", reduxState.form.lyCountryWellForm === undefined ? "ALL" : reduxState.form.lyCountryWellForm.values.country);
        urlencoded.append("language", reduxState.form.languageWellForm === undefined ? "ALL" : reduxState.form.languageWellForm.values.language);
        
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        }
        return fetch(reduxState.loginReducer.base_url + "/report/graduates/wellness/lastyear", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: LAST_YEAR_WELLNESS_GRADUATES_REPORT, payload: json.data });
                    dispatch({ type: SUCCESSFUL_LOAD });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const lastMonthWellnessGraduates = () => {

    return (dispatch, getState) => {
        dispatch({ type: DELETE_SUCCESSFUL });
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("country", reduxState.form.lmCountryWellForm === undefined ? "ALL" : reduxState.form.lmCountryWellForm.values.country);
        urlencoded.append("language", reduxState.form.lmLanguageWellForm === undefined ? "ALL" : reduxState.form.lmLanguageWellForm.values.language);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        }
        return fetch(reduxState.loginReducer.base_url + "/report/graduates/wellness/lastmonth", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: LAST_MONTH_WELLNESS_GRADUATES_REPORT, payload: json.data });
                    dispatch({ type: SUCCESSFUL_LOAD });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}


export const lastSixMonthsWellnessGraduates = () => {

    return (dispatch, getState) => {
        dispatch({ type: DELETE_SUCCESSFUL });
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("country", reduxState.form.lsmCountryWellForm === undefined ? "ALL" : reduxState.form.lsmCountryWellForm.values.country);
        urlencoded.append("language", reduxState.form.lsmLanguageWellForm === undefined ? "ALL" : reduxState.form.lsmLanguageWellForm.values.language);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        }
        return fetch(reduxState.loginReducer.base_url + "/report/graduates/wellness/lastsixmonths", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: LAST_SIX_MONTHS_WELLNESS_GRADUATES_REPORT, payload: json.data });
                    dispatch({ type: SUCCESSFUL_LOAD });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const dateRangeWellnessGraduates = () => {

    return (dispatch, getState) => {
        dispatch({ type: DELETE_SUCCESSFUL });
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("start_date", convertDate(reduxState.form.dateRangeWellForm.values.start_date));
        urlencoded.append("final_date", convertDate(reduxState.form.dateRangeWellForm.values.final_date));
        urlencoded.append("country", reduxState.form.dateRangeWellForm.values.country);
        urlencoded.append("language", reduxState.form.dateRangeWellForm.values.language);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        }

        return fetch(reduxState.loginReducer.base_url + "/report/graduates/wellness/daterange", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: DATE_RANGE_WELLNESS_GRADUATES_REPORT, payload: json.data });
                    dispatch({ type: SUCCESSFUL_LOAD });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};
export const lastYearWellnessParticipantGraduates = () => {

    return (dispatch, getState) => {
        dispatch({ type: DELETE_SUCCESSFUL });
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("country", reduxState.form.lyCountryWellPaForm === undefined ? "ALL" : reduxState.form.lyCountryWellPaForm.values.country);
        urlencoded.append("language", reduxState.form.languageWellPaForm === undefined ? "ALL" : reduxState.form.languageWellPaForm.values.language);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        }
        return fetch(reduxState.loginReducer.base_url + "/report/graduates/wellnessparticipant/lastyear", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: LAST_YEAR_WELLNESS_PARTICIPANT_GRADUATES_REPORT, payload: json.data });
                    dispatch({ type: SUCCESSFUL_LOAD });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const lastMonthWellnessParticipantGraduates = () => {

    return (dispatch, getState) => {
        dispatch({ type: DELETE_SUCCESSFUL });
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("country", reduxState.form.lmCountryWellPaForm === undefined ? "ALL" : reduxState.form.lmCountryWellPaForm.values.country);
        urlencoded.append("language", reduxState.form.lmLanguageWellPaForm === undefined ? "ALL" : reduxState.form.lmLanguageWellPaForm.values.language);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        }
        return fetch(reduxState.loginReducer.base_url + "/report/graduates/wellnessparticipant/lastmonth", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: LAST_MONTH_WELLNESS_PARTICIPANT_GRADUATES_REPORT, payload: json.data });
                    dispatch({ type: SUCCESSFUL_LOAD });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}


export const lastSixMonthsWellnessParticipantGraduates = () => {

    return (dispatch, getState) => {
        dispatch({ type: DELETE_SUCCESSFUL });
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("country", reduxState.form.lsmCountryWellPaForm === undefined ? "ALL" : reduxState.form.lsmCountryWellPaForm.values.country);
        urlencoded.append("language", reduxState.form.lsmLanguageWellPaForm === undefined ? "ALL" : reduxState.form.lsmLanguageWellPaForm.values.language);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        }
        return fetch(reduxState.loginReducer.base_url + "/report/graduates/wellnessparticipant/lastsixmonths", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: LAST_SIX_MONTHS_WELLNESS_PARTICIPANT_GRADUATES_REPORT, payload: json.data });
                    dispatch({ type: SUCCESSFUL_LOAD });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
}

export const dateRangeWellnessParticipantGraduates = () => {

    return (dispatch, getState) => {
        dispatch({ type: DELETE_SUCCESSFUL });
        const reduxState = getState();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Authorization", "Bearer " + reduxState.loginReducer.token);

        var urlencoded = new URLSearchParams();
        urlencoded.append("start_date", convertDate(reduxState.form.dateRangeWellPaForm.values.start_date));
        urlencoded.append("final_date", convertDate(reduxState.form.dateRangeWellPaForm.values.final_date));
        urlencoded.append("country", reduxState.form.dateRangeWellPaForm.values.country);
        urlencoded.append("language", reduxState.form.dateRangeWellPaForm.values.language);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        }

        return fetch(reduxState.loginReducer.base_url + "/report/graduates/wellnessparticipant/daterange", requestOptions)
            .then(response => response.json())
            .then(json => {
                if (json.code === 401 ) {
                    dispatch({ type: SESSION_EXPIRED });
                    history.push("/login");
                } else {
                    dispatch({ type: DATE_RANGE_WELLNESS_PARTICIPANT_GRADUATES_REPORT, payload: json.data });
                    dispatch({ type: SUCCESSFUL_LOAD });
                }
            })
            .catch(() => {
                history.push("/login");
            });
    }
};