import { NEW_TRANSFER } from "constants/actionTypes";
import { SHOW_TRANSFER, LOAD_FORM_TRANSFER, EDIT_TRANSFER, ERROR_EDIT_TRANSFER } from "constants/actionTypes";
import { DELETE_TRANSFER } from "constants/actionTypes";

const initialState = { 
  new_transfer:{
   name:"",
   bank_name:"",
   account_number:"",
   swift_code:"",
   type:""
  },
  show_transfer:{
    name:"",
    bank_name:"",
    account_number:"",
    swift_code:"",
    type:""
  },
  loading: true,
  editError: false
}
export const transferReducer = (state = initialState, action) => {
    switch (action.type) {     
      case NEW_TRANSFER:
        return Object.assign({}, state, {
          new_transfer: action.payload
        });
      case SHOW_TRANSFER:
        return Object.assign({}, state, {
          show_transfer: action.payload
        });
      case LOAD_FORM_TRANSFER:
        return Object.assign({}, state, {
          data: action.data
        });
      case EDIT_TRANSFER:
        return Object.assign({}, state, {
          edit_transfer: action.payload
        }); 
      case ERROR_EDIT_TRANSFER:
        return Object.assign({}, state, {
          editError: true
        })
     
    }
    return state;
}