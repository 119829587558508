import { SHOW_PROGRESS_CONTROL } from "constants/actionTypes";
import { PROGRESS_CONTROL_LIST } from "constants/actionTypes";
import { PROGRESS_CONTROL_ACCESS_TAB } from "constants/actionTypes";

const initialState = {
  show_progress_control: [],
  progress_control_list: [],
  progress_control_access_tab: [],
  loading: true
}

export const progressControlReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_PROGRESS_CONTROL:
      return Object.assign({}, state, {
        show_progress_control: action.payload,
      });
    case PROGRESS_CONTROL_LIST:
      return Object.assign({}, state, {
        progress_control_list: action.payload,
      });
    case PROGRESS_CONTROL_ACCESS_TAB:
      return Object.assign({}, state, {
        progress_control_access_tab: action.payload,
      });
  }
  return state;
}

