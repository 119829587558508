import React from "react";
import { translate } from 'react-switch-lang';
import withStyles from "@material-ui/core/styles/withStyles";
import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import Logo from "assets/img/logo_interweave.png";

const styles = {
    ...sweetAlertStyle
};

class Login extends React.Component {
    render() {
        const { t } = this.props;
        return (
            <SweetAlert
                style={{ display: "block", marginTop: "-100px", close: true }}
                showConfirm={false}
            >
                <img src={Logo} height="90px" alt="..." />
                <h4>{t("label_loading")}</h4>
            </SweetAlert>
        );
    }
}

export default translate(withStyles(styles)(Login));
