import { SUCCESSFULL_SEND_REVISION_PROJECT } from "constants/actionTypes";
import { SUCCESSFUL_NEW_POST_EVALUATION } from "constants/actionTypes";
import { SUCCESSFULL_APPROVE_PROJECT } from "constants/actionTypes";
import { SUCCESSFULL_EDIT_REVISION } from "constants/actionTypes";
import { ERROR_EDIT_REVISION } from "constants/actionTypes";
import { ERROR_APPROVE_PROJECT } from "constants/actionTypes";
import { ERROR_SEND_REVISION_PROJECT } from "constants/actionTypes";
import { HIDE_REVISION_ALERT } from "constants/actionTypes";
import { SHOW_EXPRESS } from "constants/actionTypes";
import { SHOW_EXPRESS_PROGRESS } from "constants/actionTypes";

const initialState = {
    show_express_progress: {
        requirements: {
            business: "0",
            business_budget: "0",
            checklist: "0",
            income_expense: "0",
            income_statement: "0",
            money_management: "0",
            post_evaluation: "0",
            quality_life: "0",
            service: "0",
            success_story: "0",
        },
        corrections: {},
        total_progress: "0",
    },
    newExpress: {
        plan1: "",
        plan2: "",
        product1: "",
        product3: "",
        product4: "",
        product5: "",
        product6: "",
        product7: "",
        process1: [" "],
        process2: "",
        process3: "",
        process4: "",
        price1: "",
        price2: "",
        price3: "",
        price4: "",
        promotion1: "",
        promotion2: "",
        promotion3: "",
        promotion4: "",
        promotion5: "",
        promotion6: "",
        promotion7: "",
        promotion8: "",
        promotion9: "",
        promotion10: "",
        paperwork1: "",
        paperwork2: "option1",
        paperwork3: { "option1": true },
        paperwork4: { p4_initial_capital: "", p4_expenses: "", p4_income: "", p4_balance: "", p4_array: ["", "", "", "", "", "", "", "", "", ""] },
        paperwork5: { p5_initial_capital: "", p5_array: ["", "", "", ""], p5_balance: "", p5_income: "" },
        paperwork6: { p6_initial_capital: "", p6_expenses: "", p6_earnings_loses: "", p6_balance: "", p6_array: ["", "", "", ""] },
        paperwork7: { p7_month: ["", "", "", "", ""], p7_initial_capital: ["", "", "", "", "", "", "", "", ""], p7_title: ["", "", "", "", "", "", "", "", ""], p7_income: ["", "", "", "", "", "", "", "", ""], p7_array: ["", "", "", "", "", "", "", "", "", ""] },
        paperwork8: { p8_ending_balance: ["", "", "", "", "", "", "", "", ""], p8_profit_loss_balance: ["", "", "", "", "", "", "", "", ""], p8_opening_balance: ["", "", "", "", "", "", "", "", ""], p8_balance: ["", "", "", "", "", "", "", "", ""], p8_expenses: ["", "", "", "", "", "", "", "", ""], p8_earnings_loses: ["", "", "", "", "", "", "", "", ""], p8_array: ["", "", "", "", "", "", "", "", "", ""] },
        paperwork10: {
            p10_month: "",
            p10_income_plan: "",
            p10_income_actual: "",
            p10_expenses_plan: "",
            p10_expenses_actual: "",
            p10_donation_plan: "",
            p10_donation_actual: "",
            p10_saving_plan: "",
            p10_saving_actual: "",
            p10_rent_plan: "",
            p10_rent_actual: "",
            p10_utility_plan: "",
            p10_utility_actual: "",
            p10_food_plan: "",
            p10_food_actual: "",
            p10_clothing_plan: "",
            p10_clothing_actual: "",
            p10_school_plan: "",
            p10_school_actual: "",
            p10_transportation_plan: "",
            p10_transportation_actual: "",
            p10_medical_plan: "",
            p10_medical_actual: "",
            p10_insurance_plan: "",
            p10_insurance_actual: "",
            p10_entertainment_plan: "",
            p10_entertainment_actual: "",
            p10_other_1_plan: "",
            p10_other_1_actual: "",
            p10_other_2_plan: "",
            p10_other_2_actual: "",
            p10_total_expenses_plan: "",
            p10_total_expenses_actual: "",
            p10_total_income_plan: "",
            p10_total_income_actual: "",
            p10_array: ["", "", "", "", "", ""]
        },
        paperwork11: { p11_array: ["", "", "", "", "", ""] },
        paperwork12: "option1",
        paperwork13: false,
        paperwork17: "",
        paperwork18: "",
        quality_p1: "",
        quality_p2: "",
        quality_p3: "",
        quality_p4: "",
        quality_p5: "",
        quality_p6: "",
        quality_p7: "",
        quality_p8: "",
        quality_q1: "274,119",
        quality_q2: "303,132",
        quality_q3: "314,159",
        quality_q4: "303,188",
        quality_q5: "274,199",
        quality_q6: "248,187",
        quality_q7: "234,159",
        quality_q8: "247,132",
        quality_g1: "",
        quality_g2: "",
        quality_g3: "",
        quality_g4: "",
        quality_g5: "",
        quality_g6: "",
        quality_g7: "",
        qualityg8: "",
        service1: "",
        service2: "",
        service3: "",
        service4: "",
        service5: "",
        service6: "",
        service7: "",
        service8: false,
        service9: false,
        history2: "",
        state: "",
        statusplan: "",
        statusproduct: "",
        statusprice: "",
        statuspromotion: "",
        statuspaperwork: "",
        statusprocess: "",
        statusquality: "",
        statusservice: "",
        product_name: "",
        product_description: "",
        product_phone: "",
        product_web: "option.clothing",
        code: "",
        areacode: "1",
        student: {
            id: -1,
            username: "",
            language: "",
            language_grader: [],
            first_name: "",
            last_name: "",
            country: "",
            city: "",
            whatsapp: "",
            roles: [],
            groupes: [],
            studentgroup: {
                id: "",
                group: {
                    id: "",
                    name: "",
                    start_date: "",
                    final_date: "",
                    graduation_date: "",
                    number_students: "",
                    modality: "",
                    program: "",
                    interweave_local: "",
                    embassador: {
                        id: "",
                        username: "",
                        language: "",
                        language_grader: [],
                        first_name: "",
                        last_name: "",
                        country: "",
                        city: "",
                        whatsapp: "",
                    }
                }
            }
        },
    },
    programexpress: {
        plan1: "",
        plan2: "",
        product1: "",
        product3: "",
        product4: "",
        product5: "",
        product6: "",
        product7: "",
        process1: [" "],
        process2: "",
        process3: "",
        process4: "",
        price1: "",
        price2: "",
        price3: "",
        price4: "",
        promotion1: "",
        promotion2: "",
        promotion3: "",
        promotion4: "",
        promotion5: "",
        promotion6: "",
        promotion7: "",
        promotion8: "",
        promotion9: "",
        promotion10: "",
        paperwork1: "",
        paperwork2: "option1",
        paperwork3: { "option1": true },
        paperwork4: { p4_initial_capital: "", p4_expenses: "", p4_income: "", p4_balance: "", p4_array: ["", "", "", "", "", "", "", "", "", ""] },
        paperwork5: { p5_initial_capital: "", p5_array: ["", "", "", ""], p5_balance: "", p5_income: "" },
        paperwork6: { p6_initial_capital: "", p6_expenses: "", p6_earnings_loses: "", p6_balance: "", p6_array: ["", "", "", ""] },
        paperwork7: { p7_month: ["", "", "", "", ""], p7_initial_capital: ["", "", "", "", "", "", "", "", ""], p7_title: ["", "", "", "", "", "", "", "", ""], p7_income: ["", "", "", "", "", "", "", "", ""], p7_array: ["", "", "", "", "", "", "", "", "", ""] },
        paperwork8: { p8_ending_balance: ["", "", "", "", "", "", "", "", ""], p8_profit_loss_balance: ["", "", "", "", "", "", "", "", ""], p8_opening_balance: ["", "", "", "", "", "", "", "", ""], p8_balance: ["", "", "", "", "", "", "", "", ""], p8_expenses: ["", "", "", "", "", "", "", "", ""], p8_earnings_loses: ["", "", "", "", "", "", "", "", ""], p8_array: ["", "", "", "", "", "", "", "", "", ""] },
        paperwork10: {
            p10_month: "",
            p10_income_plan: "",
            p10_income_actual: "",
            p10_expenses_plan: "",
            p10_expenses_actual: "",
            p10_donation_plan: "",
            p10_donation_actual: "",
            p10_saving_plan: "",
            p10_saving_actual: "",
            p10_rent_plan: "",
            p10_rent_actual: "",
            p10_utility_plan: "",
            p10_utility_actual: "",
            p10_food_plan: "",
            p10_food_actual: "",
            p10_clothing_plan: "",
            p10_clothing_actual: "",
            p10_school_plan: "",
            p10_school_actual: "",
            p10_transportation_plan: "",
            p10_transportation_actual: "",
            p10_medical_plan: "",
            p10_medical_actual: "",
            p10_insurance_plan: "",
            p10_insurance_actual: "",
            p10_entertainment_plan: "",
            p10_entertainment_actual: "",
            p10_other_1_plan: "",
            p10_other_1_actual: "",
            p10_other_2_plan: "",
            p10_other_2_actual: "",
            p10_total_expenses_plan: "",
            p10_total_expenses_actual: "",
            p10_total_income_plan: "",
            p10_total_income_actual: "",
            p10_array: ["", "", "", "", "", ""]
        },
        paperwork11: { p11_array: ["", "", "", "", "", ""] },
        paperwork12: "option1",
        paperwork13: false,
        paperwork17: "",
        paperwork18: "",
        quality_p1: "",
        quality_p2: "",
        quality_p3: "",
        quality_p4: "",
        quality_p5: "",
        quality_p6: "",
        quality_p7: "",
        quality_p8: "",
        quality_q1: "274,119",
        quality_q2: "303,132",
        quality_q3: "314,159",
        quality_q4: "303,188",
        quality_q5: "274,199",
        quality_q6: "248,187",
        quality_q7: "234,159",
        quality_q8: "247,132",
        quality_g1: "",
        quality_g2: "",
        quality_g3: "",
        quality_g4: "",
        quality_g5: "",
        quality_g6: "",
        quality_g7: "",
        qualityg8: "",
        service1: "",
        service2: "",
        service3: "",
        service4: "",
        service5: "",
        service6: "",
        service7: "",
        service8: false,
        service9: false,
        history2: "",
        state: "",
        statusplan: "",
        statusproduct: "",
        statusprice: "",
        statuspromotion: "",
        statuspaperwork: "",
        statusprocess: "",
        statusquality: "",
        statusservice: "",
        product_name: "",
        product_description: "",
        product_phone: "",
        product_web: "option.clothing",
        code: "",
        areacode: "1",
        student: {
            id: 142,
            username: "",
            language: "",
            language_grader: [],
            first_name: "",
            last_name: "",
            country: "",
            city: "",
            whatsapp: "",
            roles: [],
            groupes: [],
            studentgroup: {
                id: "",
                group: {
                    id: "",
                    name: "",
                    start_date: "",
                    final_date: "",
                    graduation_date: "",
                    number_students: "",
                    modality: "",
                    program: "",
                    interweave_local: "",
                    embassador: {
                        id: "",
                        username: "",
                        language: "",
                        language_grader: [],
                        first_name: "",
                        last_name: "",
                        country: "",
                        city: "",
                        whatsapp: "",
                    }
                }
            }
        },
    },
    editRevisionError: false,
    editRevisionSuccessfull: false,
    approveProjectError: false,
    approveProjectSuccessfull: false,
    sendRevisionProjectError: false,
    sendRevisionProjectSuccessfull: false,
    newPostEvaluationSuccessful: false,
}

export const expressReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_EXPRESS:
            var programexpress = action.payload;
            if (programexpress !== undefined && typeof programexpress === "object") {
                programexpress.paperwork9 = programexpress.paperwork3;
            }else {
                programexpress = initialState.newExpress
            }
            return Object.assign({}, state, {
                programexpress: programexpress,
            });
        case SHOW_EXPRESS_PROGRESS:
            return Object.assign({}, state, {
                show_express_progress: action.payload,
            });
        case ERROR_EDIT_REVISION:
            return Object.assign({}, state, {
                editRevisionError: true
            });

        case SUCCESSFULL_EDIT_REVISION:
            return Object.assign({}, state, {
                editRevisionSuccessfull: true
            });

        case ERROR_APPROVE_PROJECT:
            return Object.assign({}, state, {
                approveProjectError: true
            });

        case SUCCESSFULL_APPROVE_PROJECT:
            return Object.assign({}, state, {
                approveProjectSuccessfull: true
            });

        case ERROR_SEND_REVISION_PROJECT:
            return Object.assign({}, state, {
                sendRevisionProjectError: true
            });

        case SUCCESSFULL_SEND_REVISION_PROJECT:
            return Object.assign({}, state, {
                sendRevisionProjectSuccessfull: true
            });
        case SUCCESSFUL_NEW_POST_EVALUATION:
            return Object.assign({}, state, {
                newPostEvaluationSuccessful: true
            });
        case HIDE_REVISION_ALERT:
            return Object.assign({}, state, {
                editRevisionError: false,
                editRevisionSuccessfull: false,
                approveProjectError: false,
                approveProjectSuccessfull: false,
                sendRevisionProjectError: false,
                sendRevisionProjectSuccessfull: false,
                newPostEvaluationSuccessful: false
            });

    }
    return state;
}

