import { HIDE_REVISION_ALERT } from "constants/actionTypes";
import { ERROR_SEND_REVISION_WELLNESS } from "constants/actionTypes";
import { SUCCESSFUL_APPROVE_WELLNESS } from "constants/actionTypes";
import { SHOW_WELLNESS } from "constants/actionTypes";
import { WELLNESS_PROGRESS } from "constants/actionTypes";
import { LOAD_FORM_WELLNESS } from "constants/actionTypes";
import { SHOW_WELLNESS_PARTICIPANT } from "constants/actionTypes";
import { ERROR_SEND_REVISION_PROJECT } from "constants/actionTypes";
import { ERROR_APPROVE_PROJECT } from "constants/actionTypes";
import { SUCCESSFULL_APPROVE_PROJECT } from "constants/actionTypes";
import { SUCCESSFULL_SEND_REVISION_PROJECT } from "constants/actionTypes";
import { RESET_PAGINATED_WELLNESS_LIST } from "constants/actionTypes";
import { NEW_WELLNESS } from "constants/actionTypes";
import { ERROR_APPROVE_WELLNESS } from "constants/actionTypes";
import { SUCCESSFUL_SEND_REVISION_WELLNESS } from "constants/actionTypes";
import { ERROR_EDIT_REVISION } from "constants/actionTypes";
import { SUCCESSFULL_EDIT_REVISION } from "constants/actionTypes";
import { PAGINATED_WELLNESS_LIST } from "constants/actionTypes";
import { LOADING_WELLNESS } from "constants/actionTypes";

const initialState = {
  editRevisionError: false,
  editRevisionSuccessful: false,
  sendRevisionWellnessSuccessful: false,
  sendRevisionWellnessError: false,
  aproveWellnessSuccessful: false,
  aproveWellnessError: false,
  approveProjectError: false,
  approveProjectSuccessfull: false,
  sendRevisionProjectError: false,
  sendRevisionProjectSuccessfull: false,
  new_wellness: {
    wellness1: "",
    wellness2: "",
    wellness3: "",
    wellness4: "",
    wellness5: "",
    wellness6: "",
    wellness7: "",
    wellness8: "",
    wellness9: "",
    wellness10: "",
    wellness11: "",
    wellness12: "",
    wellness13: "",
    wellness14: "",
    wellness15: "",
  },
  show_wellness: {
    id: "",
    wellness1: "",
    wellness2: "",
    wellness3: "",
    wellness4: "",
    wellness5: "",
    wellness6: "",
    wellness7: "",
    student: {
      id: 1,
      username: "",
      language: "",
      language_grader: [],
      first_name: "",
      last_name: "",
      country: "",
      city: "",
      whatsapp: "",
      roles: [],
      groupes: [],
      studentgroup: {
        id: "",
        group: {
          id: "",
          name: "",
          start_date: "",
          final_date: "",
          graduation_date: "",
          number_students: "",
          modality: "",
          program: "",
          interweave_local: "",
          embassador: {
            id: "",
            username: "",
            language: "",
            language_grader: [],
            first_name: "",
            last_name: "",
            country: "",
            city: "",
            whatsapp: "",
          }
        }
      }
    },
  },
  show_wellness_participant: {
    id: "",
    wellness1: "",
    wellness2: "",
    wellness3: "",
    wellness4: "",
    wellness5: "",
    wellness6: "",
    wellness7: "",
    student: {
      id: 1,
      username: "",
      language: "",
      language_grader: [],
      first_name: "",
      last_name: "",
      country: "",
      city: "",
      whatsapp: "",
      roles: [],
      groupes: [],
      studentgroup: {
        id: "",
        group: {
          id: "",
          name: "",
          start_date: "",
          final_date: "",
          graduation_date: "",
          number_students: "",
          modality: "",
          program: "",
          interweave_local: "",
          embassador: {
            id: "",
            username: "",
            language: "",
            language_grader: [],
            first_name: "",
            last_name: "",
            country: "",
            city: "",
            whatsapp: "",
          }
        }
      }
    },
  },
  revisionplan: "",
  wellness_progress: {
    requirements: {
      ambassador_skills: 0,
      pilot_group: 0,
      plans_future: 0,
      conclusions: 0,
    },
    total_progress: 0,
  },
  pages_total: "1",
  paginated_wellness_list: [],
  approved_paginated_wellness_list: [],
  wellness_pagination_params: {
    keyword: "",
    page: 1,
    page_size: 10,
    order_column: "updated_at",
    order_direction: "DESC",
  },
  approved_wellness_pagination_params: {
    keyword: "",
    page: 1,
    page_size: 10,
    order_column: "approval_date",
    order_direction: "DESC",
  },
}

export const wellnessReducer = (state = initialState, action) => {
  switch (action.type) {

    case HIDE_REVISION_ALERT:
      return Object.assign({}, state, {
        editRevisionError: false,
        editRevisionSuccessful: false,
        sendRevisionWellnessError: false,
        sendRevisionWellnessSuccessful: false,
        aproveWellnessSuccessful: false,
        aproveWellnessError: false,
        approveProjectError: false,
        approveProjectSuccessfull: false,
        sendRevisionProjectError: false,
        sendRevisionProjectSuccessfull: false,
      });
    case NEW_WELLNESS:
      return Object.assign({}, state, {
        new_wellness: action.payload,
      });
    case SHOW_WELLNESS:
      return Object.assign({}, state, {
        show_wellness: action.payload,
      });
    case SHOW_WELLNESS_PARTICIPANT:
      return Object.assign({}, state, {
        show_wellness_participant: action.payload,
      });
    case LOAD_FORM_WELLNESS:
      return Object.assign({}, state, {
        data_wellness: action.data
      });
    case SUCCESSFULL_EDIT_REVISION:
      return Object.assign({}, state, {
        editRevisionSuccessful: true
      });

    case ERROR_EDIT_REVISION:
      return Object.assign({}, state, {
        editRevisionError: true
      });
    case SUCCESSFUL_SEND_REVISION_WELLNESS:
      return Object.assign({}, state, {
        sendRevisionWellnessSuccessful: true
      });

    case ERROR_SEND_REVISION_WELLNESS:
      return Object.assign({}, state, {
        sendRevisionWellnessError: true
      });
    case ERROR_APPROVE_WELLNESS:
      return Object.assign({}, state, {
        aproveWellnessError: true
      });
    case SUCCESSFUL_APPROVE_WELLNESS:
      return Object.assign({}, state, {
        aproveWellnessSuccessful: true
      });
    case WELLNESS_PROGRESS:
      return Object.assign({}, state, {
        wellness_progress: action.payload,
      });
    case PAGINATED_WELLNESS_LIST:
      return Object.assign({}, state, {
        paginated_wellness_list: action.payload.list,
        pages_total: action.payload.pages_total,
        loading: false
      });
    case RESET_PAGINATED_WELLNESS_LIST:
      return Object.assign({}, state, {
        paginated_wellness_list: [],
        pages_total: "1",
      });
    case LOADING_WELLNESS:
      return Object.assign({}, state, {
        loading: true
      });
    case ERROR_APPROVE_PROJECT:
      return Object.assign({}, state, {
        approveProjectError: true
      });

    case SUCCESSFULL_APPROVE_PROJECT:
      return Object.assign({}, state, {
        approveProjectSuccessfull: true
      });

    case ERROR_SEND_REVISION_PROJECT:
      return Object.assign({}, state, {
        sendRevisionProjectError: true
      });

    case SUCCESSFULL_SEND_REVISION_PROJECT:
      return Object.assign({}, state, {
        sendRevisionProjectSuccessfull: true
      });

  }
  return state;
}

